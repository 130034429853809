import { useEffect, useRef, useState } from "react";
import { formatCurrency } from "../../../../Utils/Utils";
import { TranslatePaymentMethod, TranslatePaymentStatus, TranslatePaymentType, TranslateTransactionType } from "../../../../Utils/TranslationUtils";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";

import commonStyle from "../../CommonStyles.module.css";
import useApi from "../../../../Utils/BackendClient";
const Payments = () => {
    const toast = useRef();
    const { fetchPayments, fetchPaymentTransactions } = useApi();

    const [payments, setPayments] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchPayments(lazyState);

            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setPayments(response.data.results);
            }
            else{
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку платежів", detail: "Користувач не має права перегляду списку платежів", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку платежів", detail: "Помилка серверу " + response.status, life: 3000 });
                }
            }

            setLoading(false);
        }

        fetchData();

    }, [lazyState, fetchPayments]);

    const getPaymentTransactions = (rowData) => {

        const fetchData = async () => {

            const response = await fetchPaymentTransactions(rowData.id);

            if (response.status === 200){
                setTransactions(response.data.results);
            }
            else
            {
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку транзакцій", detail: "Користувач не має права перегляду списку платежів", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку транзакцій", detail: "Помилка серверу " + response.status, life: 3000 });
                }
            }
        }

        fetchData();
        setVisible(true);
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const methodTemplate = (rowData) => {
        return TranslatePaymentMethod(rowData.method);
    }

    const paymentTypeTemplate = (rowData) => {
        return TranslatePaymentType(rowData.type);
    }

    const statusTemplate = (rowData) => {
        return TranslatePaymentStatus(rowData.status);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <Button
                label="Транзакції"
                icon="pi pi-eye"
                onClick={() => getPaymentTransactions(rowData)}
                text
            />
        );
    };

    return (<>
        <Toast ref={toast} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={payments} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="date" header="Дата"/>
                    <Column field="method" header="Метод" body={methodTemplate}/>
                    <Column field="type" header="Тип оплати" body={paymentTypeTemplate}/>     
                    <Column field="sum" header="Сума" body={(rowData) => formatCurrency(rowData.sum, rowData.currency)}/>
                    <Column field="status" header="Статус оплати" body={statusTemplate}/>
                    <Column field="cagent_name" header="Контрагент" />
                    <Column field="undistributed_sum" header="Нерозподілена сума" body={(rowData) => formatCurrency(rowData.undistributed_sum, rowData.currency)}/>
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
        </div>
        <Sidebar visible={visible} position="right" onHide={() => setVisible(false)} className={commonStyle.sidebarTable}>
            <h2>Список транзакцій</h2>
            <DataTable value={transactions} stripedRows >            
                <Column field="transaction_id" header="ID"/>
                <Column field="transaction_type" header="Тип" body={(rowData) => TranslateTransactionType(rowData.transaction_type)}/>
                <Column field="act_id" header="ID акту"/>  
                <Column field="distribution_sum" header="Розподілена сума" body={(rowData) => formatCurrency(rowData.distribution_sum, rowData.currency)} />
                <Column field="distribution_sum_UAH" header="Розподілена сума, грн" body={(rowData) => formatCurrency(rowData.distribution_sum_UAH, "UAH")} />
                <Column field="cagent_name" header="Контрагент" />
            </DataTable>
        </Sidebar>
    </>)
}

export default Payments;
import ActStatusCircle from "../../../UIElements/StatusCircle";
import EnumsContext from "../../../../context/enums-context";
import { formatCurrency } from "../../../../Utils/Utils";
import useApi from "../../../../Utils/BackendClient";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";
import { useContext, useEffect, useRef, useState } from "react";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from 'primereact/toast';
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";

const ProductsReception = () => {

    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [acts, setActs] = useState(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters:{
            cagent: { value: null, matchMode: 'contains' },
            division: {value: null, matchMode: 'contains' },
            pay_status: {value: null, matchMode: 'contains' }
        }
    });
    const [reloadData, setReloadData] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const buttonRef = useRef(null);
    const ctx = useContext(EnumsContext);
    const { fetchReceiveActs } = useApi();
    const authenticatedRequest = useAuthenticatedRequest();

    useGetEnums(["divisions", "partners", "pay_statuses"]);

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }
        location.state = null;
    },[location.state, location]);

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchReceiveActs(lazyState);

            if (response.status === 200) {

                setTotalRecords(response.data.count);
                setActs(response.data.results);

            }
            else{
                setActs([]);
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку актів", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, fetchReceiveActs]);

    const FinalizeActHandler = (rowData) => {

        const finalizeAct = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "receive_acts/make_finalized/" + rowData.id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 200){ 
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        finalizeAct();
    }

    const DeleteButtonHandler = rowData => {
        const deleteAct = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "receive_acts/delete/" + rowData.id + "/";
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }
            setReloadData(!reloadData);
        }

        deleteAct();
    }
    
    const CreateNewSaleButtonHandler = () => {
        navigate("/productsreception/newreception");
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setlazyState(event);
    }

    const cagentRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.partners).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)} 
                optionLabel="label" 
                className="p-column-filter"
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const divisionRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.divisions).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label" 
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const payStatusRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.pay_statuses).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label" 
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const confirmDelete = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Після фіналізації акту, буде відсутня можливість його редагування',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {FinalizeActHandler(rowData)}
        });
    }  

    const EditProductHandler = rowData => {
        const route = '/productsreception/editreception/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    /*const printPriceTag = rowData => {

        const getActDetails = async() => {
            const url = process.env.REACT_APP_BACKEND_URL + "receive_acts/get_details/" + rowData.id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            if (response.status === 200) {

                const parsedResponse = await response.json();

                const productsToPrint = [];

                if (parsedResponse.identified_products !== undefined && parsedResponse.identified_products.length > 0) {
                    parsedResponse.identified_products.forEach(product => {
                        const newProduct = {};
                        newProduct.remaining_id = product.id;
                        newProduct.accounting_type = "INDIVIDUAL";
                        productsToPrint.push(newProduct);
                    });
                }

                if (parsedResponse.unidentified_products !== undefined && parsedResponse.unidentified_products.length > 0) {
                    parsedResponse.unidentified_products.forEach(product => {
                        const newProduct = {};
                        newProduct.remaining_id = product.product_id;
                        newProduct.accounting_type = "PIECE";
                        productsToPrint.push(newProduct);
                    });
                }

                return productsToPrint;
            }
            else{
                toast.current.show({ severity: "error", summary: "Помилка завантаження деталей акту", detail: "Помилка сервера " + response.status, life: 3000 });
                return null;
            }
        }

        const printPriceTags = async() => {

            const productsToPrintPrice = await getActDetails();
            console.log(productsToPrintPrice);

            const url = process.env.REACT_APP_BACKEND_URL + "remainings/get_pdf_with_qr_code/";
            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(productsToPrintPrice)
            };

            const response = await authenticatedRequest(url, options);
            if(response.status === 200){
                console.log(response);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
        
                // Set a filename
                link.download = `price_${rowData.id}.pdf`; // Change based on your needs
                document.body.appendChild(link);
                link.click();
        
                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);
            }
            else{
                toast.current.show({ severity: 'error', summary: 'Помилка завантаження', detail: 'Помилка завантадення цінника', life: 3000 });
            }
        }

        printPriceTags();
    } */

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати акт прийому товару" severity="success" className={commonStyle.addButton} onClick={CreateNewSaleButtonHandler} />
        </div>)
    }

    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('ua-UA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    const dateBodyTemplate = rawElement => {
        return formatDate(rawElement.created_at);
    };

    const formatAdditionalButtons = (rowData) => {
        let buttons = [];
        if(rowData.status !== "FINALIZED"){
            buttons.push({
                label: 'Фіналізувати',
                icon: 'pi pi-lock',
                command: (event) => { confirmFinalize(event, rowData) }
            });
        }
        
        if(rowData.can_be_deleted){
            buttons.push({
                label: 'Видалити',
                icon: 'pi pi-trash',
                command: (event) => { confirmDelete(event, rowData) }
            });
        }

        buttons = buttons.length > 0 ? buttons : null;
        return buttons
    };

    const actionBodyTemplate = (rowData) => {
        const buttons = formatAdditionalButtons(rowData);
        
        const buttonLabel = rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту";
        const buttonIcon = rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye";

        return (
            <>
                {buttons !== null ? (
                    <SplitButton 
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditProductHandler(rowData)} 
                        model={buttons}
                        text
                        ref={buttonRef} // Set the ref here
                    />
                ) : (
                    <Button
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditProductHandler(rowData)} 
                        text
                    />
                )}
            </>
        );
    };

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={acts} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]}
                filterDisplay="row" dataKey="id" onFilter={onFilter} filters={lazyState.filters} emptyMessage="Актів не знайдено" tableStyle={{ minWidth: '60rem' }} >
                    <Column field="act_number" header="Номер акту" sortable/>
                    <Column field="act_date" dataType="date" header="Дата акту" sortable/>
                    <Column field="cagent" header="Контрагент" sortable filter filterElement={cagentRowFilterTemplate} body={rowData => ctx.partners[rowData.cagent]} style={{ width: '13%' }}/>
                    <Column field="division" header="Підрозділ" sortable filter filterElement={divisionRowFilterTemplate} body={rowData => ctx.divisions[rowData.division]} style={{ width: '10%' }}/>
                    <Column field="amountUAH" header="Сума акту, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")}/>
                    <Column field="pay_status" header="Статус оплати" filter filterElement={payStatusRowFilterTemplate} body={rowData => ctx.pay_statuses[rowData.pay_status]} style={{ width: '10%' }}/>
                    <Column field="pay_due_date" header="Дата оплати"/>
                    <Column field="status" header="Статус" style={{ width: '4%' }} body={rowData => <ActStatusCircle rowData={rowData}/>} sortable/>
                    <Column field="created_by" header="Менеджер" sortable/>
                    <Column field="created_at" header="Дата" body={dateBodyTemplate} sortable/>
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmDialog group="headless" />
        </div>
    </>);
}

export default ProductsReception;
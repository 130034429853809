import ActStatusCircle from "../../../UIElements/StatusCircle";
import EnumsContext from "../../../../context/enums-context";
import { formatCurrency } from "../../../../Utils/Utils";
import useApi from "../../../../Utils/BackendClient";
import { UaLocale } from "../../../../Utils/TranslationUtils";
import { useContext, useEffect, useRef, useState } from "react";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useLocation, useNavigate } from "react-router-dom";

import { addLocale, locale } from "primereact/api";
import { Button } from "primereact/button";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";

import commonStyle from "../../CommonStyles.module.css";
import PaymentSideBar from "../../../UIElements/PaymentSidebar";

const DistributorReturns = () => {

    const buttonRef = useRef(null);
    const ctx = useContext(EnumsContext);
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useRef(null);

    const { deleteDistributorReturnAct, fetchDistributorReturnActs, finalizeDistributorReturnAct } = useApi();

    const statuses = {
        "PENDING": "Драфт",
        "FINALIZED": "Фіналізований"
    }

    const [acts, setActs] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reloadData, setReloadData] = useState(true);
    const [visible, setVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [tableFilters, setTableFilters] = useState({
        cagent: { value: null, matchMode: 'equals' },
        status: { value: null, matchMode: 'equals' },
        pay_status: { value: null, matchMode: 'equals' },
        division: { value: null, matchMode: 'equals' },
    });
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters: {
            cagent: { value: null, matchMode: 'equals' },
            status: { value: null, matchMode: 'equals' },
            pay_status: { value: null, matchMode: 'equals' },
            division: { value: null, matchMode: 'equals' },
        }
    });

    addLocale('ua', UaLocale);
    locale('ua');
    useGetEnums(["divisions", "partners", "pay_statuses"]);

    const showToast = (msg) => {
        toast.current.show(msg);
    }

    useEffect(() => {
        if (location.state !== null) {
            showToast(location.state.toast);
        }
        location.state = null;
    }, [location.state, location]);

    useEffect(() => {
        const fetchData = async () => {

            const response = await fetchDistributorReturnActs(lazyState);

            console.log(response);

            if (response.status === 200) {

                setTotalRecords(response.data.count);
                setActs(response.data.results);

            }
            else {
                setActs([]);
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку актів", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, fetchDistributorReturnActs]);

    const hideSidebar = () => {
        setVisible(false);
    };

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const CreateNewSaleButtonHandler = () => {
        navigate("/distributorreturns/newreturn");
    }

    const DeleteButtonHandler = rowData => {
        const deleteAct = async () => {

            const response = await deleteDistributorReturnAct(rowData.id);

            if (response.status !== 204) {
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }
            setReloadData(!reloadData);
        }

        deleteAct();
    }

    const FinalizeActHandler = (rowData) => {

        const finalizeAct = async () => {

            const response = await finalizeDistributorReturnAct(rowData.id);

            if (response.status !== 200) {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else {
                toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        finalizeAct();
    }

    const PaymenButtonHandler = (rowData) => {

        setSelectedItem(rowData);
        setVisible(true);
    }

    const EditProductHandler = (rowData) => {
        const route = '/distributorreturns/editreturn/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const confirmDelete = (rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => { DeleteButtonHandler(rowData) }
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Після фіналізації акту, буде відсутня можливість його редагування',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => { FinalizeActHandler(rowData) }
        });
    }

    const formatFilterChips = () => {
        const element = [];

        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];

            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;


                if (filterKey === "pay_status") {
                    translatedKey = "Статус оплати";
                    changedValue = ctx.pay_statuses[changedValue];
                    removeValue = "";
                }
                else if (filterKey === "status") {
                    translatedKey = "Статус акту";
                    changedValue = statuses[changedValue];
                    removeValue = "";
                }
                else if (filterKey === "cagent") {
                    translatedKey = "Постачальник";
                    changedValue = ctx.partners[changedValue];
                    removeValue = "";
                }
                else if (filterKey === "division") {
                    translatedKey = "Підрозділ";
                    changedValue = ctx.divisions[changedValue];
                    removeValue = "";
                }

                element.push(
                    <Chip
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)} // Optional: To handle removal
                    />
                );
            }
        });

        return <div className="flex flex-wrap gap-2">{element}</div>;
    }

    const formatAdditionalButtons = (rowData) => {
        let buttons = [];
        if (rowData.status !== "FINALIZED") {
            buttons.push({
                label: 'Фіналізувати',
                icon: 'pi pi-lock',
                command: (event) => { confirmFinalize(event, rowData) }
            });
        }
        else {
            if (rowData.pay_status !== "PAYED") {
                buttons.push({
                    label: 'Оплатити',
                    icon: 'pi pi-money-bill',
                    command: () => { PaymenButtonHandler(rowData) }
                });
            }
        }

        if (rowData.can_be_deleted) {
            buttons.push({
                label: 'Видалити',
                icon: 'pi pi-trash',
                command: () => { confirmDelete(rowData) }
            });
        }

        buttons = buttons.length > 0 ? buttons : null;
        return buttons
    };

    const filterApply = () => {
        const editedLazyState = lazyState;
        editedLazyState.filters = tableFilters;
        setlazyState(editedLazyState);
        setReloadData(!reloadData)
    }

    const clearFilter = (paramName, value) => {
        setlazyState(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [paramName]: {
                    ...prevState.filters[paramName],
                    value: value
                }
            }
        }));
    }

    const clearFilterFromChips = (paramName, value) => {
        clearFilter(paramName, value);
        setTableFilters(prevState => ({
            ...prevState,
            [paramName]: {
                value: value
            }
        }));
    }

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
        </div>
    );

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Створити акт" severity="success" className={commonStyle.addButton} onClick={CreateNewSaleButtonHandler} />
        </div>)
    }

    const actionBodyTemplate = (rowData) => {
        const buttons = formatAdditionalButtons(rowData);

        const buttonLabel = rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту";
        const buttonIcon = rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye";

        return (
            <>
                {buttons !== null ? (
                    <SplitButton
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditProductHandler(rowData)}
                        model={buttons}
                        text
                        ref={buttonRef} // Set the ref here
                    />
                ) : (
                    <Button
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditProductHandler(rowData)}
                        text
                    />
                )}
            </>
        );
    }

    const dropDownTemplate = (options, values) => {
        const dropdownOptions = Object.entries(values).map(([key, value]) => ({
            value: key,
            label: value,
        }));

        return (
            <Dropdown
                value={options.value}
                onChange={(e) => options.filterApplyCallback(e.value)}
                options={dropdownOptions}
                placeholder="Виберіть значення"
                className="p-column-filter"
            />
        );
    };

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={acts} header={tableHeader} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]}
                    onFilter={onFilter} filters={tableFilters}>
                    <Column field="act_number" header="Номер акту" sortable style={{ width: '10%' }} />
                    <Column field="act_date" dataType="date" header="Дата акту" sortable style={{ width: '10%' }} />
                    <Column field="cagent" header="Контрагент" sortable filter filterElement={(options) => dropDownTemplate(options, ctx.partners)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("cagent", null)} showFilterMatchModes={false} body={rowData => ctx.partners[rowData.cagent]} style={{ width: '19%' }} />
                    <Column field="division" header="Підрозділ" sortable filter filterElement={(options) => dropDownTemplate(options, ctx.divisions)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("division", null)} showFilterMatchModes={false} body={rowData => ctx.divisions[rowData.division]} style={{ width: '10%' }} />
                    <Column field="amountUAH" header="Сума акту, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")} style={{ width: '10%' }} />
                    <Column field="pay_status" header="Статус оплати" filter filterElement={(options) => dropDownTemplate(options, ctx.pay_statuses)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("pay_status", null)} showFilterMatchModes={false} body={rowData => ctx.pay_statuses[rowData.pay_status]} style={{ width: '10%' }} />
                    <Column field="pay_due_date" header="Дата оплати" />
                    <Column field="status" header="Статус" sortable filter filterElement={(options) => dropDownTemplate(options, statuses)} onFilterApplyClick={filterApply}
                        onFilterClear={() => clearFilter("status", null)} showFilterMatchModes={false} body={rowData => <ActStatusCircle rowData={rowData} />} style={{ width: '6%' }} />
                    <Column field="action" body={actionBodyTemplate} style={{ width: '15%' }} />
                </DataTable>
            </div>
            <ConfirmDialog group="headless" />
        </div>
        <PaymentSideBar visible={visible} onHide={hideSidebar} onMessage={showToast} data={selectedItem} moneyMovementType="INCOME" reloadData={() => setReloadData(!reloadData)}/>
    </>
    );
};

export default DistributorReturns;
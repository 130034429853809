import EnumsContext from "../../../../context/enums-context";
import { formatCurrency } from "../../../../Utils/Utils";
import { useContext, useEffect, useRef, useState } from "react";
import useApi from "../../../../Utils/BackendClient";
import useGetEnums from "../../../../Utils/EnumsUtils";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";

import commonStyle from "../../CommonStyles.module.css";
import { TranslateTransactionType } from "../../../../Utils/TranslationUtils";
import { Sidebar } from "primereact/sidebar";

const Transactions = () => {

    const toast = useRef();
    const ctx = useContext(EnumsContext);
    const { fetchTransactionPayments, fetchTransactions } = useApi();

    const [transactions, setTransactions] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    useGetEnums(["pay_statuses"]);

    useEffect(() => {
        const fetchData = async () => {
           
            const response = await fetchTransactions(lazyState);

            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setTransactions(response.data.results);
            }
            else{
                setTotalRecords(0);
                setTransactions([]);

                toast.current.show({ severity: "error", summary: "Помилка завантаження списку клієнтів", detail: "Помилка сервера " + response.status, life: 3000 });
            }

            setLoading(false);
        }

        fetchData();

    }, [lazyState, fetchTransactions]);

    const getTransactionPayments = (rowData) => {

        const fetchData = async () => {

            const response = await fetchTransactionPayments(rowData.id);

            if (response.status !== 200){
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setPayments([]);
            }
            else
            {
                setPayments(response.data.results);
            }
        }

        fetchData();
        setVisible(true);
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const closeSideBarHandler = () =>{
        setVisible(false);
    }

    const transactionTemplate = (rowData) => {
        return TranslateTransactionType(rowData.type);
    }

    const payStatusTemplate = (rowData) => {
        return ctx.pay_statuses[rowData.pay_status];
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <Button
                label="Платежі"
                icon="pi pi-eye"
                onClick={() => getTransactionPayments(rowData)}
                text
            />
        );
    };

    return (<>
        <Toast ref={toast} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={transactions} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage}
                    first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="act_number" header="Номер акту" />
                    <Column field="type" header="Тип" body={transactionTemplate}/>
                    <Column field="sum" header="Сума" body={(rowData) => formatCurrency(rowData.sum, rowData.currency)} />
                    <Column field="sumUAH" header="Сума, грн" body={(rowData) => formatCurrency(rowData.sumUAH, "UAH")} />
                    <Column field="cagent_name" header="Контрагент" />
                    <Column field="pay_status" header="Статус оплати" body={payStatusTemplate}/>
                    <Column field="pay_due_date" header="Очікувана дата оплати"/>
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
        </div>
        <Sidebar visible={visible} position="right" onHide={() => closeSideBarHandler()} className={commonStyle.sidebarTable}>
            <h2>Список платежів</h2>
            <DataTable value={payments} stripedRows >                
                <Column field="payment_id" header="ID платежу"/>
                <Column field="distribution_sum" header="Розподілена сума" body={(rowData) => formatCurrency(rowData.distribution_sum, rowData.currency)} />
                <Column field="distribution_sum_UAH" header="Розподілена сума, грн" body={(rowData) => formatCurrency(rowData.distribution_sum_UAH, "UAH")} />
                <Column field="cagent_name" header="Контрагент" />
            </DataTable>
        </Sidebar>
    </>)
}

export default Transactions;
import EnumsContext from "../../../../context/enums-context";
import { findKeyByValue, formatCurrency, formatShortDate } from "../../../../Utils/Utils";
import useApi from "../../../../Utils/BackendClient";
import { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Sidebar } from "primereact/sidebar";

import commonStyle from "../../CommonStyles.module.css"
import { Toast } from "primereact/toast";

const NewProductsReturn = () => {

    const ctx = useContext(EnumsContext);
    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const { createClientReturnAct, fetchClientReturnActDetails, fetchSaleActDetails, fetchSaleActs, updateClientReturnAct } = useApi();

    const [isFinalized, setIsFinalized] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [visible, setVisible] = useState(false);
    const [userSales, setUserSales] = useState(null);
    const [selectedAct, setSelectedAct] = useState(null);
    const [actProducts, setActProducts] = useState(null);
    const [searchDate, setSearchDate] = useState(null);
    const [totaLActSum, setTotalActSum] = useState(0);
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
    const [quantitySideBarVisible, setQuantitySidebarVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [actData, setActData] = useState
    ({
        cagent: null,
        division: ctx.divisions["1"],
        act_number: '',
        act_number_autogenerate: true,
        act_date: new Date(),
        pay_due_date: new Date(),
        status: "Драфт",
        identified_products: [],
        unidentified_products: [],
    });

    useGetEnums(["divisions", "pay_statuses", "clients"]);

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (location.state !== null) {
            
            if(location.state.id !== undefined){
                const getReturnActDetails = async() => {
                    const response = await fetchClientReturnActDetails(location.state.id);
                    console.log(response.data);
    
                    if(response.status === 200){
                        response.data.division = ctx.divisions[response.data.division];
                        response.data.cagent = ctx.clients[response.data.cagent];
                        setIsFinalized(response.data.status !== "PENDING");
                        response.data.status = response.data.status === "PENDING" ? "Драфт" : "Фіналізований";
                        response.data.pay_status = ctx.pay_statuses[response.data.pay_status];
                        response.data.act_date = new Date(response.data.act_date);
                        response.data.pay_due_date = new Date(response.data.pay_due_date);
    
                        let totalValue = 0
                        if (response.data.identified_products.length > 0) {
                            totalValue += response.data.identified_products.reduce((accumulator, element) => accumulator + element.priceUAH, 0);
                            response.data.identified_products.forEach(element => {
                                element.totalPrice = element.price;
                                element.totalPriceUAH = element.priceUAH;
                            });
                        }
    
                        if (response.data.unidentified_products.length > 0) {
                            response.data.totalPrice = response.data.price * response.data.quantity;
                            totalValue += response.data.unidentified_products.reduce((accumulator, element) => accumulator + element.priceUAH * element.quantity, 0);
                            response.data.unidentified_products.forEach(element => {
                                element.totalPrice = element.price * element.quantity;
                                element.totalPriceUAH = element.priceUAH * element.quantity;
                            });
                        }
    
                        setTotalActSum(totalValue);
                        setActData(response.data);
                    }
    
                    setIsEdit(true);
                };
    
                getReturnActDetails();
            }
            else if(location.state.saleId !== undefined){
                console.log(location.state);
                const getSaleActDetails = async() => {
                    const response = await fetchSaleActDetails(location.state.saleId);

                    if(response.status === 200){
                        let returnAct = {
                            cagent: response.data.cagent !== null ? ctx.clients[response.data.cagent] : null,
                            division: ctx.divisions[response.data.division],
                            act_number: '',
                            act_number_autogenerate: true,
                            act_date: new Date(),
                            pay_due_date: new Date(),
                            status: "Драфт",
                            identified_products: [],
                            unidentified_products: [],
                        }
                        
                        let totalValue = 0;

                        if(response.data.identified_products.length > 0){
                            totalValue += response.data.identified_products.reduce((accumulator, element) => accumulator + element.priceUAH, 0);

                            response.data.identified_products.forEach(product => {
                                product.totalPrice = product.price;
                                product.totalPriceUAH = product.priceUAH;
                                product.return_sale_act_id = response.data.id;
                            });
                            returnAct.identified_products = response.data.identified_products;
                        }

                        if(response.data.unidentified_products.length > 0){

                            response.data.totalPrice = response.data.price * response.data.quantity;
                            totalValue += response.data.unidentified_products.reduce((accumulator, element) => accumulator + element.priceUAH * element.quantity, 0);
                            response.data.unidentified_products.forEach(product => {
                                product.return_sale_act_id = response.data.id;
                                product.totalPrice = product.price * product.quantity;
                                product.totalPriceUAH = product.priceUAH * product.quantity;
                            });
                            returnAct.unidentified_products = response.data.unidentified_products;
                        }

                        setTotalActSum(totalValue);
                        setActData(returnAct);
                    }
                }

                getSaleActDetails();
            }
        }
    },[ctx.clients, ctx.divisions, ctx.pay_statuses, fetchClientReturnActDetails, fetchSaleActDetails, location.state]);

    const formik = useFormik({
        initialValues: actData,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.division === null || values.division === undefined) {
                errors.division = "Виберіть підрозділ";
            }

            if (!values.act_number_autogenerate){
                if (values.act_number === null || values.act_number === "") {
                    errors.act_number = "Введіть номер акту";
                }
            }

            if(values.identified_products.length === 0 && values.unidentified_products.length === 0){
                errors.products = "Додайте будь ласка товари"
            }
            return errors;
        },
        onSubmit: (values) => {
            values.act_date = formatShortDate(values.act_date);
            values.cagent = values.cagent !== null ? findKeyByValue(ctx.clients, values.cagent) : null;
            values.division = findKeyByValue(ctx.divisions, values.division);
            values.pay_due_date = formatShortDate(values.pay_due_date);
            values.act_number = values.act_number === "" ? null : values.act_number;

            console.log("Final values: ", values);

            const saveReturnAct = async() => {

                const response = isEdit ? await updateClientReturnAct(values.id, values) : await createClientReturnAct(values);

                console.log(response);
                
                let toastMessage = { severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення", 
                    detail: "Акт не " + (isEdit ? "відредаговано. " : " створено. ") + " Код помилки: " + response.status, life: 3000 };

                const successStatus = isEdit ? 200 : 201;
    
                if (response.status === successStatus) {
                    toastMessage = { severity: "success", summary: "Успіх " + (isEdit ? "редагування" : "cтворення"), detail: "Акт успішно " + (isEdit ? "відредаговано" : "створено"), life: 3000 }
                }
    
                navigate("/productsreturn", { state: { toast: toastMessage}});
            }

            saveReturnAct();
        }
    });

    const GetUserSalesActs = (date) => {

        const formattedDate = formatShortDate(date);

        setSearchDate(date);

        const state = {
            rows: 200,
            page: 1,
            sortField: "created_at",
            sortOrder: 1,
            filters:{
                start_date: { value: formattedDate },
                end_date: { value: formattedDate}
            }
        };

        const getActsByDate = async () => {
            setUserSales(null);
            setSelectedAct(null);
            const response = await fetchSaleActs(state);

            if(response.status === 200)
            {
                if(response.data.results.length > 0){
                    const saleActs = response.data.results.map(act => act.id);

                    setUserSales(saleActs);
                }
                else{
                    toast.current.show({ severity: "info", summary: "Пошук актів", detail: `Не знайдено актів продажу на дату ${formattedDate}`, life: 3000 });
                }
            }
        }

        getActsByDate();
    }

    const getActDetails = (id) => {
        const getSaleActDetails = async () => {
            const response = await fetchSaleActDetails(id);

            if(response.status === 200){
                let actProducts = [];
                console.log(response.data);

                if(response.data.identified_products !== null && response.data.identified_products.length > 0){
                    actProducts = response.data.identified_products;
                }
                if(response.data.unidentified_products !== null && response.data.unidentified_products.length > 0){
                    actProducts = [...actProducts , ...response.data.unidentified_products];
                }
                console.log(actProducts);
                setActProducts(actProducts);
            }
        }

        setSelectedAct(id);
        getSaleActDetails();
    }

    const AddProductHandler = () => {
        setVisible(true);
    }

    const closeSideBarHandler = () => {
        setVisible(false);
    }

    const AddProductToHandler = (rowData) => {
        if(rowData.identifier === undefined && rowData.quantity > 1){
            setSelectedProduct(rowData);
            setQuantitySidebarVisible(true);
        }
        else{
            AddProductToAct(rowData);
        }
    }

    const AddingWithQuantity = () => {
        AddProductToAct(selectedProduct);
        setQuantitySidebarVisible(false);
    }

    const AddProductToAct = (rowData) => {

        rowData.return_sale_act_id = selectedAct;

        if (rowData.identifier !== undefined && rowData.identifier !== null) 
        {
            if(!formik.values.identified_products.some(obj => obj.identifier === rowData.identifier)){
                rowData.totalPrice = rowData.price;
                rowData.totalPriceUAH = rowData.priceUAH;
                setTotalActSum(totaLActSum + rowData.totalPriceUAH);
                let newobject = formik.values.identified_products;
                newobject = [...newobject, rowData];
                formik.setFieldValue("identified_products", newobject);
            }
            else{
                toast.current.show({ severity: "info", summary: "Помилка додавання", detail: `Товар ${rowData.product} (ID:${rowData.identifier}) уже додано`, life: 3000 });
            }
        }
        else 
        {                
            rowData.totalPrice = rowData.price * rowData.quantity;
            rowData.totalPriceUAH = rowData.priceUAH * rowData.quantity;
            setTotalActSum(totaLActSum + rowData.totalPriceUAH);
            let newobject = formik.values.unidentified_products;
            newobject = [...newobject, rowData];
            formik.setFieldValue("unidentified_products", newobject);
        }
    }

    const setSelectedProductQuantity = (amount) => {

        setSelectedProduct(prevProduct => ({
            ...prevProduct,
            quantity: amount
        }));
    }

    const DeleteFieldHandler = (rowData) => {

        let fields = [];
        let name = '';

        if (rowData.identifier !== null && rowData.identifier !== undefined) {
            fields = formik.values.identified_products;
            name = "identified_products";
        }
        else {
            fields = formik.values.unidentified_products;
            name = "unidentified_products";
        }

        const updatedFields = [...fields];

        const index = fields.findIndex(function (field) {
            return field.fe_id === rowData.fe_id;
        });

        // setTotalActSum(totaLActSum - +fields[index].totalPriceUAH);

        updatedFields.splice(index, 1);
        formik.setFieldValue(name, updatedFields);
    };

    const mainToolbarLeftTemplate = () => {
        if(isEdit){
            if(isFinalized){
                return (<h3> Перегляд акту повернення товарів #{location.state?.id} </h3>);
            }

            return (<h3> Редагування акту повернення товарів #{location.state?.id} </h3>);
        }

        return (<h3>Новий акт повернення товарів</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                {!isFinalized && <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />}
                <Button label="Вийти" severity="secondary" className={commonStyle.closeButton} onClick={() =>{navigate("/productsreturn")}} />
            </div>
        );
    }

    const contentToolbarRightTemplate = () => {
        return (<>
            <div className="flex flex-wrap gap-2">
                <Button label="+Додати товар" severity="success" className={commonStyle.addButton} onClick={AddProductHandler} type="button"/>
            </div>
        </>);
    }

    const setTableHeader = (tablename) => {
        return (
            <div className="flex flex-wrap align-items-center justify-content-between gap-2">
                <span className="text-xl text-900 font-bold">{tablename}</span>
            </div>
        );
    }

    const TableFooter = (data) => {

        const totalValue = data.reduce((accumulator, element) => accumulator + element.totalPriceUAH, 0);

        return (
            <div className={commonStyle.footerContainer}>
                <label>Cума: {formatCurrency(totalValue, "UAH")}</label>
            </div>)
    }

    const onCellEditComplete = (e) => {
        let { rowData, newValue } = e;

        const products = formik.values.unidentified_products;

        const index = products.findIndex(x => x.remaining_id === rowData.remaining_id);
        products[index].quantity = newValue;
        products[index].totalPrice = products[index].price * products[index].quantity;
        products[index].totalPriceUAH = products[index].priceUAH * products[index].quantity;

        formik.setFieldValue("unidentified_products", products);
    }

    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
    };
    
    const tableActionBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            {!isFinalized && <Button
                icon="pi pi-trash"
                type="button"
                className={`p-button-rounded p-button-danger ${commonStyle.deleteButton}`}
                tooltip="Видалити з таблиці"
                tooltipOptions={{ position: "left" }}
                onClick={() => DeleteFieldHandler(rowData)}
            />}
        </div>
        );
    };

    const nameTemplate = (rowData) => {
        return `(x${rowData.quantity === undefined ? 1 : rowData.quantity}) ${rowData.product}`;
    }

    const sideBarBodyTemplate = (rowData) => {
        return (<div className="flex flex-wrap gap-2">
            {!isFinalized && <Button
                icon="pi pi-plus"
                type="button"
                className={`p-button-rounded p-button-success ${commonStyle.addButton}`}
                tooltip="Додати"
                tooltipOptions={{ position: "left" }}
                onClick={() => AddProductToHandler(rowData)}
            />}
        </div>
        );
    };

    return (<form onSubmit={formik.handleSubmit}>
        <Toast ref={toast}/>
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <div className="grid">
            <div className={`${commonStyle.leftMenu} col-5 md:col-4 lg:col-3`}>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <Dropdown value={formik.values.division}
                                onChange={(e) => formik.setFieldValue('division', e.value)}
                                options={Object.values(ctx.divisions)}
                                disabled={isFinalized}
                                showClear
                                className={formik.errors.division && formik.touched.division ? 'p-invalid w-full' : "w-full"} />
                            <label>Підрозділ</label>
                        </FloatLabel>
                        {formik.errors.division && formik.touched.division && (<small className={commonStyle.errorSmall}>{formik.errors.division}</small>)}
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <Dropdown value={formik.values.cagent}
                                onChange={(e) => formik.setFieldValue('cagent', e.value)}
                                options={Object.values(ctx.clients)}
                                disabled={isFinalized}
                                showClear
                                className={formik.errors.cagent && formik.touched.cagent ? 'p-invalid w-full' : "w-full"} />
                            <label>Контрагент</label>
                        </FloatLabel>
                        {formik.errors.cagent && formik.touched.cagent && (<small className={commonStyle.errorSmall}>{formik.errors.cagent}</small>)}
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-1">
                        <FloatLabel>
                            <Calendar value={formik.values.act_date}
                                onChange={(e) => { formik.setFieldValue('act_date', e.target.value) }}
                                disabled={isFinalized}
                                showIcon />
                            <label>Дата акту</label>
                        </FloatLabel>
                    </div>
                </div>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-1">
                        <FloatLabel>
                            <Calendar value={formik.values.pay_due_date}
                                disabled={isFinalized}
                                onChange={(e) => { formik.setFieldValue('pay_due_date', e.target.value) }}
                                showIcon />
                            <label>Очікувана дата оплати</label>
                        </FloatLabel>
                    </div>
                </div>
                {!isEdit && <div className={commonStyle.checkBoxInput}>
                    <div className={`${commonStyle.checkboxStyle} p-inputgroup flex-1 md:w-22rem"`}>
                        <Checkbox
                            name="act_number_autogenerate"
                            onChange={(e) => { formik.setFieldValue('act_number_autogenerate', !formik.values.act_number_autogenerate) }}
                            checked={formik.values.act_number_autogenerate}
                        />
                        <label>Автоматично генерувати номер акту</label>
                    </div>
                </div>}
                {!formik.values.act_number_autogenerate && <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText value={formik.values.act_number}
                                onChange={(e) => { formik.setFieldValue('act_number', e.target.value) }}
                                disabled={isFinalized}
                                className={formik.errors.act_number && formik.touched.act_number ? 'p-invalid w-full' : "w-full"} />
                            <label>Номер акту</label>
                        </FloatLabel>
                        {formik.errors.act_number && formik.touched.act_number && (<small className={commonStyle.errorSmall}>{formik.errors.act_number}</small>)}
                    </div>
                </div>}
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText value={formik.values.status}
                                onChange={(e) => { formik.setFieldValue('status', e.target.value) }}
                                disabled
                                className="w-full" />
                            <label>Статус</label>
                        </FloatLabel>
                    </div>
                </div>
            </div>
            <div className="col-7 md:col-8 lg:col-9">
            {!isFinalized && <Toolbar className="mb-4" end={contentToolbarRightTemplate}></Toolbar>}
                {formik.values.identified_products.length > 0 && <DataTable value={formik.values.identified_products}
                    header={setTableHeader("Товари індивідуального обліку")} footer={() => TableFooter(formik.values.identified_products)} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="product" header="Продукт" style={{ width: '22%' }} />
                    <Column field="identifier" header="Ідентифікатор" style={{ width: '15%' }} />
                    <Column field="price" header="Ціна за одиницю" body={(rowdata) => formatCurrency(rowdata.price, rowdata.currency)} style={{ width: '15%' }} />
                    <Column field="priceUAH" header="Ціна за одиницю, грн" body={(rowdata) => formatCurrency(rowdata.priceUAH, "UAH")} style={{ width: '15%' }} />
                    <Column field="totalPrice" header="Загальна ціна" body={(rowData) => formatCurrency(rowData.totalPrice, rowData.currency)} style={{ width: '15%' }} />
                    <Column field="totalPriceUAH" header="Загальна ціна, грн" body={(rowData) => formatCurrency(rowData.totalPriceUAH, "UAH")} style={{ width: '15%' }} />
                    <Column field="action" body={tableActionBodyTemplate} style={{ width: '12%' }} />
                </DataTable>}
                {formik.values.unidentified_products.length > 0 && <DataTable value={formik.values.unidentified_products}
                    header={setTableHeader("Товари поштучного обліку")} editMode="cell" footer={() => TableFooter(formik.values.unidentified_products)} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="product" header="Продукт" style={{ width: '22%' }}></Column>
                    <Column field="quantity" header="Кількість" editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete} style={{ width: '15%' }}></Column>
                    <Column field="price" header="Ціна за одиницю" body={(rowdata) => formatCurrency(rowdata.price, rowdata.currency)} style={{ width: '15%' }} />
                    <Column field="priceUAH" header="Ціна за одиницю, грн" body={(rowdata) => formatCurrency(rowdata.priceUAH, "UAH")} style={{ width: '15%' }} />
                    <Column field="totalPrice" header="Загальна ціна" body={(rowData) => formatCurrency(rowData.totalPrice, rowData.currency)} style={{ width: '15%' }} />
                    <Column field="totalPriceUAH" header="Загальна ціна, грн" body={(rowData) => formatCurrency(rowData.totalPriceUAH, "UAH")} style={{ width: '15%' }} />
                    <Column field="action" body={tableActionBodyTemplate} style={{ width: '12%' }} />
                </DataTable>}
                {(formik.values.unidentified_products.length > 0 || formik.values.identified_products.length > 0) && <><div className={commonStyle.footerContainer}>
                    <h3>Загальна сума акту: {formatCurrency(totaLActSum, "UAH")}</h3>
                    </div>
                </>}
            </div>
        </div>
        <Sidebar visible={visible} position="right" className={`${commonStyle.shortSideBar} ${quantitySideBarVisible && isLargeScreen ? commonStyle.moveLeft : ''}`} onHide={() => closeSideBarHandler()}>
            <div className={commonStyle.sideBarInput}>
                <div className="p-inputgroup flex-column">
                    <FloatLabel>
                        <Calendar value={searchDate}
                            onChange={(e) => GetUserSalesActs(e.value)}
                            className="w-full" />
                        <label>Дата акту продажу</label>
                    </FloatLabel>
                </div>
            </div>
            {userSales !== null && <div className={commonStyle.sideBarInput}>
                <div className="p-inputgroup flex-column">
                    <FloatLabel>
                        <Dropdown value={selectedAct}
                            onChange={(e) => getActDetails(e.value)}
                            options={userSales}
                            showClear
                            className="w-full"/>
                        <label>Акт</label>
                    </FloatLabel>
                    {formik.errors.cagent && formik.touched.cagent && (<small className={commonStyle.errorSmall}>{formik.errors.cagent}</small>)}
                </div>
            </div>}
            {(selectedAct !== null && actProducts !== null) && <div>
                <DataTable value={actProducts}>
                    <Column field="product" header="Продукт" body={nameTemplate}/>
                    <Column field="identifier" header="ID"/>            
                    <Column field="action" body={sideBarBodyTemplate}/>
                </DataTable>
            </div>}
        </Sidebar>
        <Sidebar visible={quantitySideBarVisible} position="right" className={`${commonStyle.sidebarmoved} ${commonStyle.shortSideBar} ${!isLargeScreen && 'sidebar-mobile'}`} onHide={() => setQuantitySidebarVisible(false)}>
            <div className={commonStyle.formInput}>
                <div className="p-inputgroup flex-column">
                    <FloatLabel>
                        <InputText value={selectedProduct?.quantity}
                            onChange={(e) => setSelectedProductQuantity(e.target.value)}
                            className={formik.errors.act_number && formik.touched.act_number ? 'p-invalid w-full' : "w-full"} />
                        <label>Кількість</label>
                    </FloatLabel>
                    {formik.errors.act_number && formik.touched.act_number && (<small className={commonStyle.errorSmall}>{formik.errors.act_number}</small>)}
                </div>
            </div>
            <div>
                <Button label="Додати" severity="success" type="button"  onClick={() => {AddingWithQuantity()}} className={`${commonStyle.sideBarButton} ${commonStyle.addButton}`} autoFocus />
            </div>
        </Sidebar>
    </form>);
}

export default NewProductsReturn;
import EnumsContext from "../../../../context/enums-context";
import { formatCurrency, formatDate } from "../../../../Utils/Utils";
import ActStatusCircle from "../../../UIElements/StatusCircle";
import useApi from "../../../../Utils/BackendClient";
import useGetEnums from "../../../../Utils/EnumsUtils";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { SplitButton } from "primereact/splitbutton";

import commonStyle from "../../CommonStyles.module.css";

const ProductsReturn = () => {

    const { deleteClientReturnAct, fetchClientReturnActs, finalizeClientReturnAct } = useApi();
    const navigate = useNavigate();
    const location = useLocation();
    const buttonRef = useRef(null);
    const toast = useRef(null);
    const ctx = useContext(EnumsContext);
    useGetEnums(["divisions", "clients", "pay_statuses"]);

    const [productReturnActs, setProductReturnActs] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [reloadData, setReloadData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1,
        filters:{
            cagent: { value: null, matchMode: 'contains' },
            division: {value: null, matchMode: 'contains' },
            pay_status: {value: null, matchMode: 'contains' }
        }
    });

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }

        location.state = null;
    },[location.state, location]);

    useEffect(() => {

        const getClientsReturns = async () => {
            const response = await fetchClientReturnActs(lazyState);

            if(response.status === 200){
                setTotalRecords(response.data.count);
                setProductReturnActs(response.data.results);
            }
            else{
                if(response.status === 403){
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку повернень", detail: "Користувач не має права переглядати сторінку", life: 3000 });
                }
                else{
                    toast.current.show({ severity: "error", summary: "Помилка завантаження списку повернень", detail: "Помилкв сервера: " + response.status, life: 3000 });
                }
            }

            setLoading(false);
        }

        getClientsReturns();

    },[lazyState, reloadData, fetchClientReturnActs]);

    const CreateNewProductsReturnButtonHandler = () => {
        navigate("/productsreturn/newproductsreturn");
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
        console.log(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setlazyState(event);
    }

    const cagentRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.clients).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)} 
                optionLabel="label" 
                className="p-column-filter"
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const divisionRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.divisions).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label" 
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const payStatusRowFilterTemplate = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={Object.entries(ctx.pay_statuses).map(([id, name]) => ({ label: name, value: id }))}
                onChange={(e) => options.filterApplyCallback(e.value)}
                optionLabel="label" 
                className="p-column-filter"
                style={{ minWidth: '10rem' }}
            />
        );
    };

    const confirmDelete = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmDialog({
            group: 'headless',
            header: 'Підтвердження',
            message: 'Після фіналізації акту, буде відсутня можливість його редагування',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {FinalizeActHandler(rowData)}
        });
    }  

    const DeleteButtonHandler = (rowData) => {
        const deleteAct = async () => {

            const response = await deleteClientReturnAct(rowData.id);
            //logging to remove later
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        deleteAct();
    }

    const FinalizeActHandler = (rowData) => {

        const finalizeAct = async () => {

            const response = await finalizeClientReturnAct(rowData.id);

            if (response.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
            }
            else if (response.status === 404) {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Акту " + rowData.id + " не знайдено", life: 3000 });
            }
            else if (response.status === 405) {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Акт " + rowData.id + " уже фіналізовано", life: 3000 });
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
            }

            setReloadData(!reloadData);
        }

        finalizeAct();
    }

    const EditReturnActHandler = rowData => {
        const route = '/productsreturn/editproductsreturn/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    const formatAdditionalButtons = (rowData) => {
        let buttons = [];
        if(rowData.status !== "FINALIZED"){
            buttons.push({
                label: 'Фіналізувати',
                icon: 'pi pi-lock',
                command: (event) => { confirmFinalize(event, rowData) }
            });
        }
        
        if(rowData.can_be_deleted){
            buttons.push({
                label: 'Видалити',
                icon: 'pi pi-trash',
                command: (event) => { confirmDelete(event, rowData) }
            });
        }

        buttons = buttons.length > 0 ? buttons : null;
        return buttons
    };

    const actionBodyTemplate = (rowData) => {
        const buttons = formatAdditionalButtons(rowData);
        
        const buttonLabel = rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту";
        const buttonIcon = rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye";

        return (
            <>
                {buttons !== null ? (
                    <SplitButton 
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditReturnActHandler(rowData)} 
                        model={buttons}
                        text
                        ref={buttonRef} // Set the ref here
                    />
                ) : (
                    <Button
                        label={buttonLabel}
                        icon={buttonIcon}
                        onClick={() => EditReturnActHandler(rowData)} 
                        text
                    />
                )}
            </>
        );
    };

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати акт повернення товару" severity="success" className={commonStyle.addButton} onClick={CreateNewProductsReturnButtonHandler} />
        </div>)
    }

    return (<>
        <Toast ref={toast}/>
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={productReturnActs} stripedRows lazy rows={lazyState.rows} onPage={onPage} loading={loading} first={lazyState.first} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                filterDisplay="row" dataKey="id" onFilter={onFilter} filters={lazyState.filters} emptyMessage="Актів повернення не знайдено" paginator totalRecords={totalRecords} rowsPerPageOptions={[10, 20]}>
                    <Column field="act_number" header="Номер акту" sortable  style={{ width: '7%' }}/>
                    <Column field="act_date" dataType="date" header="Дата акту" />
                    <Column field="division" header="Підрозділ" sortable filter filterElement={divisionRowFilterTemplate} body={rowData => ctx.divisions[rowData.division]} style={{ width: '10%' }} />
                    <Column field="cagent" header="Клієнт" sortable filter filterElement={cagentRowFilterTemplate} body={rowData => ctx.clients[rowData.cagent]} style={{ width: '13%' }} />
                    <Column field="amountUAH" header="Сума, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")} sortable />
                    <Column field="pay_status" header="Оплачено" filter filterElement={payStatusRowFilterTemplate} body={rowData => ctx.pay_statuses[rowData.pay_status]} style={{ width: '10%' }} />
                    <Column field="status" header="Статус" body={rowData => <ActStatusCircle rowData={rowData}/>} sortable style={{ width: '4%' }} />
                    <Column field="created_by" header="Користувач" />
                    <Column field="created_at" dataType="date" header="Дата" body={rowData => formatDate(rowData.created_at)} sortable />
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmDialog group="headless" />
        </div>
    </>);
}

export default ProductsReturn;
import { useCallback } from 'react';
import useAuthenticatedRequest from './TokenrRefreshUtils';

const useApi = () => {
    const authenticatedRequest = useAuthenticatedRequest();

    const handleApiCall = useCallback(async (url, options) => {
        const response = await authenticatedRequest(url, options);
        const clientResponse = {};
        clientResponse.status = response.status;
        if (response.status === 200 || response.status === 201 || response.status === 204) {
            try{
                clientResponse.data = await response.json();
            }
            catch(error){
                console.log("Error parsing response", error)
            }
        }
        return clientResponse;
    }, [authenticatedRequest]);

    const handleApiCallUnParsed = useCallback(async (url, options) => {
        const response = await authenticatedRequest(url, options);

        return response;
    }, [authenticatedRequest]);

    const appendFilterToUrl = (url, filterKey, filterValue) => {
        return (filterValue !== null && filterValue !== undefined) ? `${url}&${filterKey}=${filterValue}` : url;
    };

    //accounting_payments controller
    const createPayment = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const fetchPayments = useCallback((state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_list/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchPaymentTransactions = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/get_payment_distributed_transactions/?payment_id=${id}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    //accounting_transactions controller
    const fetchTransactions = useCallback((state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_transactions/get_list/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchTransactionPayments = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_transactions/get_distributed_payments/?transaction_id=${id}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    //auth controller
    const createUser = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/create_user/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    const fetchUserData = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/users/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchUserPermissions = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/get_user_permissions/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchUserRoles = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}roles/get_list/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const updateUser = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}auth/update_user/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //cagents controller
    const deleteClient = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchClients = useCallback((state) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_clients/?page=${state.page}&page_size=${state.rows}`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    //client_return_acts controller
    const createClientReturnAct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const fetchClientReturnActDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/get_details/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchClientReturnActs = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        url = appendFilterToUrl(url, "cagent", state.filters.cagent?.value);
        url = appendFilterToUrl(url, "division", state.filters.division?.value);
        url = appendFilterToUrl(url, "pay_status", state.filters.pay_status?.value);
        url = appendFilterToUrl(url, "start_act_date", state.filters.start_date?.value);
        url = appendFilterToUrl(url, "end_act_date", state.filters.end_date?.value);

        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const finalizeClientReturnAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/finalize/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const deleteClientReturnAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const updateClientReturnAct = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}client_return_acts/update/${id}/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    //currency controller
    //dashboards controller
    const fetchCostsWidget = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}dashboards/costs_widget/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchProductCountWidget = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}dashboards/count_products_widget/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);
    
    const fetchRevenueWidget = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}dashboards/revenue_widget/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchServicesGivenWidget = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}dashboards/count_services_widget`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    //divisions controller
    //enums controller 
    const fetchProductsExtended = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}enums/products_extended/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchServicesExtended = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}enums/services_extended/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);
    
    //organization controller
    const fetchOrganizationDetails = useCallback(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}organization/get_details/`;
        return handleApiCall(url, { method: "GET"});
    },[handleApiCall]);

    const uploadOrganizationLogo = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}organization/update_logo/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                redirect: "follow",
                body: request
            });
    },[handleApiCall]);

    //products controller
    const changeProductGroupStatus = useCallback((id, action) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/${action}/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const createProduct = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/products/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const createProductGroup = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteProductGroup = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/${id}/delete/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    const fetchProductDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/products/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchProductGroupDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchProductGroups = useCallback((onlyActive, state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}products/groups/?page=${state.page}&page_size=${state.rows}`;
        url = onlyActive ? url + "&is_active=" + onlyActive : url;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        return handleApiCall(url, { method: "GET"});
    }, [handleApiCall]);

    const fetchProducts = useCallback((onlyActive, state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}products/products/?page=${state.page}&page_size=${state.rows}`;
        url = onlyActive ? url + "&is_active=" + onlyActive : url;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);

        url = appendFilterToUrl(url, "product_group", state.filters.productGroup?.value);

        return handleApiCall(url, { method: "GET"});
    }, [handleApiCall]);

    const updateProductGroup = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/groups/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    const updateProduct = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}products/products/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    //receive_acts controller
    const fetchReceiveActs = useCallback((state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}receive_acts/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        url = appendFilterToUrl(url, "cagent", state.filters.cagent?.value);
        url = appendFilterToUrl(url, "division", state.filters.division?.value);
        url = appendFilterToUrl(url, "pay_status", state.filters.pay_status?.value);
        url = appendFilterToUrl(url, "start_act_date", state.filters.start_date?.value);
        url = appendFilterToUrl(url, "end_act_date", state.filters.end_date?.value);

        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    //remainings controller
    const fetchRemainingQRCode = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}remainings/get_pdf_with_qr_code/`;
        return handleApiCallUnParsed(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCallUnParsed]);

    const fetchRemainings = useCallback((state, groupFilter) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}remainings/get_remainings/?page=${state.page}&page_size=${state.rows}`;
        url = groupFilter !== null ? url += `&product_group=${groupFilter}` : url;
        return handleApiCall(url, { method: "GET"});
    }, [handleApiCall]);
    
    const updateProductPrice = useCallback((request) => {

        const url = `${process.env.REACT_APP_BACKEND_URL}remainings/update_sale_price/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);
    
    //roles controller
    //sale_acts controller
    const fetchSaleActDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/get_details/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchSaleActs = useCallback((state) =>{
        let url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/get_list/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        url = appendFilterToUrl(url, "cagent", state.filters.cagent?.value);
        url = appendFilterToUrl(url, "division", state.filters.division?.value);
        url = appendFilterToUrl(url, "pay_status", state.filters.pay_status?.value);
        url = appendFilterToUrl(url, "start_act_date", state.filters.start_date?.value);
        url = appendFilterToUrl(url, "end_act_date", state.filters.end_date?.value);

        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const finalizeSaleAct = useCallback((id) =>{
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/make_finalized/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const deleteSaleAct = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/delete/${id}/`;
        return handleApiCall(url, { method: 'DELETE' });
    },[handleApiCall]);

    //services controller
    const changeServiceGroupStatus = useCallback((id, action) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/${action}/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const createService = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/services/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const createServiceGroup = useCallback((request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/create/`;
        return handleApiCall(
            url,
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    },[handleApiCall]);

    const deleteServiceGroup = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/${id}/delete/`;
        return handleApiCall(url, { method: 'DELETE' });
    }, [handleApiCall]);

    const fetchServiceDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/services/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    }, [handleApiCall]);

    const fetchServiceGroupDetails = useCallback((id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/${id}/`;
        return handleApiCall(url, { method: 'GET' });
    },[handleApiCall]);

    const fetchServiceGroups = useCallback((onlyActive, state) => {
        let url = `${process.env.REACT_APP_BACKEND_URL}services/groups/?page=${state.page}&page_size=${state.rows}`;
        url = onlyActive ? url + "&is_active=" + onlyActive : url;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);
        return handleApiCall(url, { method: "GET"});
    }, [handleApiCall]);

    const fetchServices = useCallback((state) => {
        console.log(state);
        let url = `${process.env.REACT_APP_BACKEND_URL}services/services/?page=${state.page}&page_size=${state.rows}`;
        url = state.sortField == null ? url : url + "&ordering=" + (state.sortOrder === 1 ? "-" + state.sortField : state.sortField);

        url = appendFilterToUrl(url, "service_group", state.filters.serviceGroup?.value);

        return handleApiCall(url, { method: "GET"});
    },[handleApiCall]);

    const updateService = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/services/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    const updateServiceGroup = useCallback((id, request) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}services/groups/${id}/update/`;
        return handleApiCall(
            url,
            {
                method: "PUT",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            });
    }, [handleApiCall]);

    return {
        changeProductGroupStatus,
        changeServiceGroupStatus,
        createClientReturnAct,
        createPayment,
        createProduct,
        createProductGroup,
        createService,
        createServiceGroup,
        createUser,
        deleteClient,
        deleteSaleAct,
        fetchCostsWidget,
        deleteClientReturnAct,
        deleteProductGroup,
        deleteServiceGroup,
        fetchClientReturnActDetails,
        fetchClientReturnActs,
        fetchClients,
        fetchOrganizationDetails,
        fetchPayments,
        fetchPaymentTransactions,
        fetchProductCountWidget,
        fetchProductDetails,
        fetchProductGroupDetails,
        fetchProductGroups,
        fetchProducts,
        fetchProductsExtended,
        fetchReceiveActs,
        fetchRemainingQRCode,
        fetchRemainings,
        fetchRevenueWidget,
        fetchSaleActDetails,
        fetchSaleActs,
        fetchServiceDetails,
        fetchServices,
        fetchServicesExtended,
        fetchServicesGivenWidget,
        fetchServiceGroupDetails,
        fetchServiceGroups,
        fetchTransactionPayments,
        fetchTransactions,
        fetchUserData,
        fetchUserPermissions,
        fetchUserRoles,
        finalizeClientReturnAct,
        finalizeSaleAct,
        updateClientReturnAct,
        updateProduct,
        updateProductGroup,
        updateProductPrice,
        updateService,
        updateServiceGroup,
        updateUser,
        uploadOrganizationLogo
    };
};

export default useApi;
const paymentMethodTranslations = {
    "INCOME": "Прихід",
    "OUTCOME": "Розхід"
}

const paymentTypeTranslations = {
    "CASH": "Готівка",
    "CARD": "Термінал",
    "IBAN": "Рахунок"
}

const paymentStatusTranslation = {
    "DISTRIBUTED" : "Розподілено",
    "NOT_DISTRIBUTED" : "Не розподілено",
    "PARTLY_DISTRIBUTED": "Частково розподілено"
}

const transactionTypeTranslation = {
    "DEBIT": "Дебет",
    "CREDIT": "Кредит"
}

const translations = {
    "auth": "Користувачі та ролі",
    "cagents": "Контрагенти",
    "currency": "Валюта",
    "dashboards": "Дашборди",
    "divisions": "Підрозділи",
    "organizations": "Організації",
    "payments": "Платежі",
    "products": "Товари",
    "receive_acts":"Акти отримання",
    "remainings": "Залишки",
    "sales_acts": "Акти продажу",
    "services": "Послуги",
    "transactions": "Транзакції"
}

export const TranslatePaymentMethod = (name) => {
    return paymentMethodTranslations[name];
}

export const TranslatePaymentType = (name) => {
    return paymentTypeTranslations[name];
}

export const TranslatePaymentStatus = (name) => {
    return paymentStatusTranslation[name];
}

export const TranslateTransactionType = (name) => {
    return transactionTypeTranslation[name];
}

export const Translate = (name) => {
    return translations[name] === undefined ? name : translations[name]; 
}
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState, useEffect } from "react";
import useApi from "../../../../Utils/BackendClient";
import { formatCurrency, systemCurrencies } from "../../../../Utils/Utils";
import { InputText } from "primereact/inputtext";
import { Chip } from "primereact/chip";

const Settlements = () => {
    const { fetchSettlements } = useApi();
    const [expandedRows, setExpandedRows] = useState([])
    const [settlements, setSettlements] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [tableFilters, setTableFilters] = useState({
        name: { value: "", matchMode: 'contains' },
    });
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "name",
        sortOrder: 1,
        filters: {
            name: { value: "", matchMode: 'contains' }
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetchSettlements(lazyState);
            if (response.status === 200) {
                setTotalRecords(response.data.count);
                setSettlements(response.data.results);
            }
            setLoading(false);
        };
        fetchData();
    }, [lazyState, fetchSettlements]);

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const onFilter = (event) => {
        event.page = 1;
        setTableFilters(event.filters);
    }

    const filterApply = () => {
        setlazyState(prevState => ({
            ...prevState,
            filters: tableFilters
        }))
    }

    const clearFilter = (paramName, value) => {
        setlazyState(prevState => ({
            ...prevState,
            filters: {
                ...prevState.filters,
                [paramName]: {
                    ...prevState.filters[paramName],
                    value: value
                }
            }
        }));
    }

    const clearFilterFromChips = (paramName, value) => {
        clearFilter(paramName, value);

        setTableFilters(prevState => ({
            ...prevState,
            [paramName]: {
                value: value
            }
        }));
    }

    const formatFilterChips = () => {
        const element = [];

        Object.keys(lazyState.filters).forEach((filterKey) => {
            const filter = lazyState.filters[filterKey];

            if (filter.value && filter.value !== "") {

                let translatedKey = filterKey;
                let changedValue = filter.value;
                let removeValue = null;


                if (filterKey === "name") {
                    translatedKey = "Назва контрагента";
                    removeValue = "";
                }

                element.push(
                    <Chip
                        key={filterKey}
                        label={`${translatedKey}: ${changedValue}`}
                        removable
                        onRemove={() => clearFilterFromChips(filterKey, removeValue)}
                    />
                );
            }
        });

        return <div className="flex flex-wrap gap-2">{element}</div>;
    }

    const tableHeader = (
        <div className="flex justify-content-between align-items-center w-full">
            {formatFilterChips()}
        </div>
    );

    const expandedRowTemplate = (rowData) => {
        const data = [];
        systemCurrencies.forEach(currency => {
            let currencyData = {};
            currencyData.currency = currency;
            currencyData.receivable = formatCurrency(rowData.receivable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);
            currencyData.overdue_receivable = formatCurrency(rowData.overdue_receivable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);
            currencyData.payable = formatCurrency(rowData.payable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);
            currencyData.overdue_payable = formatCurrency(rowData.overdue_payable_by_currency.find(x => x.currency === currency)?.sum ?? 0, currency);

            data.push(currencyData);
        });

        return (
            <DataTable value={data}>
                <Column style={{ width: '5%' }} headerStyle={{ display: 'none' }}/>
                <Column field="currency" style={{ width: '15%' }} headerStyle={{ display: 'none' }}/>
                <Column field="receivable" header="Дебіторська заборгованість" body={(data) => <span style={{ color: "#03C04A"}}>{data.receivable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
                <Column field="overdue_receivable" header="(в тому числі прострочена)" body={(data) => <span style={{ color: "#03C04A"}}>{data.overdue_receivable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
                <Column field="payable_UAH" header="Кредиторська заборгованість" body={(data) => <span style={{ color: "#EF4444"}}>{data.payable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
                <Column field="payable_UAH" header="(в тому числі прострочена)" body={(data) => <span style={{ color: "#EF4444"}}>{data.overdue_payable}</span>} 
                        style={{ width: '20%' }} headerStyle={{ display: 'none' }}/>
            </DataTable>
        );
    };

    const inputTemplate = (options) => {
        return (
            <InputText
                value={options.value}
                onChange={
                    (e) => options.filterApplyCallback(e.target.value)
                }
            />
        );
    }

    return (
        <div className="grid mt-3">
            <div className="col-12 justify-content-center">
                <DataTable
                    value={settlements}
                    header={tableHeader}
                    lazy
                    rows={lazyState.rows}
                    paginator
                    onPage={onPage}
                    first={lazyState.first}
                    loading={loading}
                    totalRecords={totalRecords}
                    onSort={onSort}
                    sortField={lazyState.sortField} 
                    sortOrder={lazyState.sortOrder}
                    onFilter={onFilter} 
                    filters={tableFilters}
                    onRowToggle={(e) => setExpandedRows(e.data)}
                    expandedRows={expandedRows}
                    rowExpansionTemplate={expandedRowTemplate} // Add this for expandable rows
                    rowsPerPageOptions={[10, 20, 50]}
                >
                    <Column expander style={{ width: '5%' }}/>
                    <Column field="name" header="Назва" sortable filter filterElement={inputTemplate} onFilterApplyClick={filterApply} onFilterClear={() => clearFilter("name", "")} showFilterMatchModes={false} style={{ width: '15%' }}/>
                    <Column field="receivable_UAH" header="Дебіторська заборгованість" sortable body={(rowData) => <span style={{ color: "#03C04A"}}>{formatCurrency(rowData.receivable_UAH, "UAH")}</span>} style={{ width: '20%' }}/>
                    <Column field="overdue_receivable_UAH" header="(в тому числі прострочена)" sortable body={(rowData) => <span style={{ color: "#03C04A"}}>{formatCurrency(rowData.overdue_receivable_UAH, "UAH")}</span>} style={{ width: '20%' }}/>
                    <Column field="payable_UAH" header="Кредиторська заборгованість" sortable body={(rowData) => <span style={{ color: "#EF4444"}}>{formatCurrency(rowData.payable_UAH, "UAH")}</span>} style={{ width: '20%' }}/>
                    <Column field="oerdue_payable_UAH" header="(в тому числі прострочена)" sortable body={(rowData) => <span style={{ color: "#EF4444"}}>{formatCurrency(rowData.oerdue_payable_UAH, "UAH")}</span>} style={{ width: '20%' }}/>
                </DataTable>
            </div>
        </div>
    );
};

export default Settlements;